// @import "~antd/dist/antd.less";

:root {
  --color-caf-primary: #004af7;

  --color-primary: #bdbdbd;
  --color-primary-light: #e0e0e0;

  --color-secondary: #bdbdbd;
  --color-secondary-light: #e0e0e0;

  --color-illustrations: var(--color-primary-light);
  --color-illustrations-dark: var(--color-primary);

  --color-headings: var(--color-primary);
  --color-subheadings: var(--color-primary);

  --color-button-background: var(--color-secondary);
  --color-button-text: #ffffff;

  --color-next-button-text: #ffffff;

  --color-other-texts: #1f2937;

  --color-back-button-icon: var(--color-primary);
  --color-back-button: var(--color-primary);
  --color-back-button-border: var(--color-primary);

  --color-tips-icon: var(--color-primary);
  --color-tips-background: var(--color-primary);
  --color-tips-text: var(--color-primary);

  --color-progressbar: var(--color-primary);
  --color-background-steps: #ffffff;

  --font-size: "16px";
  --font-family: "Roboto", sans-serif;
  --font-weight: 400;
}

html,
body {
  height: 100%;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: var(--font-weight) !important;
}

::selection {
  color: white !important;
  background: var(--color-primary) !important;
}

.themed-svg svg path {
  &.light {
    fill: var(--color-illustrations);
  }
  &.dark {
    fill: var(--color-illustrations-dark);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-height: 800px) and (min-width: 24rem) {
  html,
  body {
    font-size: 95%;
  }
}
@media (max-height: 700px) and (min-width: 24rem) {
  html,
  body {
    font-size: 90%;
  }
}
@media (max-height: 600px) and (min-width: 24rem) {
  html,
  body {
    font-size: 85%;
  }
}

.form-input-error-focused {
  span.ant-input-affix-wrapper {
    box-shadow: none !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

#DOM_FT_PRIMARY_TOPLEVEL_mainContainer {
  top: 12% !important;
}

.CLASS_FT_cancelElement {
  display: none !important;
}

/* ANTIGO DD */

.caf-web-dd-sdk
.caf-sdk-dd-upload-container
.caf-sdk-dd-upload-overlay {
  background: var(--color-secondary) !important;
}

.caf-web-dd-sdk
.caf-sdk-dd-upload-container
.caf-sdk-dd-upload-overlay
.caf-sdk-dd-center-container
.caf-sdk-dd-message-container
.caf-sdk-dd-label-upload {
  background: var(--color-button-background) !important;
  color: var(--color-button-text) !important;
}

.caf-sdk-dd-center-container {
  background-color: var(--color-background-steps) !important;
}

// TEXT AND SUBTEXT
.caf-sdk-dd-message-container
.caf-sdk-dd-message-text {
  color: var(--color-headings) !important;
}

.caf-sdk-dd-message-container
.caf-sdk-dd-message-sub-text {
  color: var(--color-subheadings) !important;
}

// SPINNER ICON
.caf-web-dd-sdk
.caf-sdk-dd-spinner-container
.caf-sdk-dd-spinner-icon,
// SUCCESS ICON
.caf-web-dd-sdk
.caf-sdk-dd-success-icon-container
.caf-sdk-dd-success-icon,
.caf-web-dd-sdk
.caf-sdk-dd-success-icon-container
.caf-sdk-dd-success-icon circle
{
  stroke: var(--color-illustrations) !important;
}

@keyframes caf-sdk-dd-animation-fill-success-icon {
  100% {
    box-shadow: inset 0px 0px 0px 120px rgba(var(--color-illustrations), 0.1) !important;
  }
}

// NOVO DD

@keyframes caf-sdk-dd-animation-stroke-success-circle {
  100% {
    stroke-dashoffset: 0;
    stroke: var(--color-illustrations) !important;
  }
}

.ant-input {
  background-color: transparent !important;
  color: var(--color-other-texts) !important;
}

.ant-select-selector {
  color: var(--color-other-texts) !important;
}

.ant-select-selector.transparent-background {
  background-color: transparent !important;
}

.ant-select-clear,
.ant-select-arrow {
  color: var(--color-other-texts) !important;
  background-color: transparent !important;
}

